import { TourIdentifier } from "@tsTypes/__generated__/enums/tour_identifier";

// Note: Make sure to add new flags to `app/services/flipper_gates.rb` too!
const FEATURE_FLAGS = [
  "UNCLAIMED_COMPANY_PAGE",
  "SIGN_OUT_ON_ONBOARDING",
  "SESSION_TIMEOUT",
  "COMPANY_MARKETPLACE_FILTER",
  "NEW_PROPOSAL_FORM_UI_WITH_REQUIREMENTS",
  "PEPSICO_REVIEWER_DASHBOARD_SORTING",
  "RFP_FORM_V2",
  "MARKETPLACE_PARTNER_TYPE_FILTER",
  "COMMERCIAL_APPLICATIONS",
  "RFP_FORM_V2_AUDIENCE",
  "KEYWORD_AI_AUTO_POPULATION",
] as const;

// Export object of { [name as symbol]: boolean }
export default FEATURE_FLAGS.reduce(
  (obj, flag) => ({ ...obj, [flag]: gon.feature_flags?.includes(flag) }),
  {}
) as { [F in (typeof FEATURE_FLAGS)[number]]: boolean };

/**
 * Tours that are enabled by default.
 */
const ENABLED_TOURS = [TourIdentifier.PROPOSAL_REVIEW_SYSTEM_FEEDBACK_DEADLINE_V2_2024_10_31];
export const isTourEnabled = (tour: TourIdentifier): boolean =>
  ENABLED_TOURS.includes(tour) || gon.feature_flags?.includes(`TOUR_${tour}`);
